<template>
  <div class="donation-info">
    <LogoTitle msg="捐款信息公示"></LogoTitle>
    <!-- 年份 -->
    <div class="tabber">
      <span
        v-for="item in yearList"
        :key="item"
        :class="{ active: item == selectedYear }"
        @click="changeYear(item)"
        >{{ item }}</span
      >
    </div>
    <div class="header">
      <div class="title">{{ selectedYear }}年度捐赠明细</div>
      <div class="main-box">
        <div class="chart-content">
          <div id="barChart" style="width: 100%; height: 260px"></div>
        </div>
        <div class="tatol-content">
          <div class="name">
            {{ selectedYear }}年{{ selectedMonth }}月统计信息
          </div>
          <div class="income">
            <span>{{ Number(incomeNum).toFixed(2) }}</span
            >元
          </div>
          <div class="p">当月收入</div>
        </div>
      </div>
    </div>
    <div class="list-content">
      <div class="item title">
        <div>爱心人士</div>
        <div>当月捐款数(元)</div>
        <div>捐赠日期</div>
      </div>
      <div class="list">
        <div class="data-null" v-if="donationList.length == 0">暂无名单~</div>
        <div class="item" v-else v-for="i in donationList" :key="i.id">
          <div>{{ i.contentTitle || "爱心人士" }}</div>
          <div>{{ i.contentAuthor }}元</div>
          <div>{{ i.contentDatetime.substr(0, 10) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data () {
    return {
      yearList: [], //年份列表[2021, 2020, 2019, 2018, 2017]
      selectedYear: '', //选中的年份
      selectedMonth: '01', //选中的月份
      incomeNum: 0, //当月收入
      myChart: null, //echarts对象
      donationList: [], //捐款名单
      moneyArr: [] //echarts数据
    }
  },
  mounted () {
    //获取数据并初始化图表
    this.getDonateInfo(true);
  },
  created () {
    this.getYearList();
  },
  beforeDestroy () {
    if (!this.myChart) {
      return
    }
    this.myChart.dispose()
    this.myChart = null
  },
  methods: {
    // 如果月份小于10，则在前面添加0，并且转化为字符串
    monthToString (month) {
      return month < 10 ? '0' + month : month + '';
    },

    /**
     * @description: 获取捐款统计信息
     * @param  {isChangeYear:true切换年份，默认取1月份的值  false切换月份取捐款列表}
     * @return {*}
     */
    getDonateInfo (isChangeYear) {
      this.moneyArr = [];
      let params = {
        pageNo: 1,
        pageSize: 999,
        year: this.selectedYear,
        type: 0,
        month: this.selectedMonth
      }
      this.$api.getDonateInfo(params).then((res) => {
        let { dataList, list } = res.data.data;
        //取出所有数据
        dataList.forEach(i => {
          this.moneyArr.push(i.money)
        })
        // console.log('this.moneyArr', this.moneyArr)..
        //切换年份，默认取1月份的捐款&初始化图表
        if (isChangeYear) {
          this.incomeNum = this.moneyArr[0]
          this.$nextTick(() => {
            //初始化图表
            this.initChart();
          })
        }
        //捐款名单
        this.donationList = list.rows;
      })
    },
    /**
     * @description: 初始化图表
     * @param  {arr}
     * @return {*}
     * @param {*}
     */
    initChart () {
      if (this.myChart != null && this.myChart != "" && this.myChart != undefined) {
        this.myChart.dispose();
      }
      // 基于准备好的dom，初始化echarts实例
      this.myChart = echarts.init(document.getElementById('barChart'))
      let that = this;
      let option = {
        grid: {
          left: '5px',
          right: '4%',
          bottom: '3%',
          top: '5%',
          containLabel: true
        },
        animation: false, //取消动画
        xAxis: [{
          type: 'category',
          data: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
          axisTick: {
            alignWithLabel: false,
            show: false,
            lineStyle: {
              color: 'rgba(255,255,255,0.29)'
            }
          },
          boundaryGap: true,
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff',
              fontSize: '14px',
            },
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0.29)'
            }
          },
          // 设置网格线颜色
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255,255,255,0.29)'],
              width: 1,
              type: 'solid'
            }
          },
        }],
        yAxis: [{
          type: 'value',
          min: '0',
          // max: '10000000',
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            formatter: function (p) {
              return p > 10000 ? `${p / 10000}万` : p
              // return p
            },
            textStyle: {
              color: '#fff',
              fontSize: '14px'
            }
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(255,255,255,0.29)'
            }
          },
          // 设置网格线颜色
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255,255,255,0.29)'],
              width: 1,
              type: 'solid'
            }
          },

        },

        ],
        series: [{
          name: '捐赠明细',
          type: 'bar',
          barWidth: '20px',
          // data: [10, 52, 200, 334, 390, 330, 220, 65, 442, 114, 34, 97],
          data: this.moneyArr, //this.moneyArr,
          itemStyle: {
            //柱状图颜色
            normal: {
              color: function (params) {
                //通过判断选中的名字改变柱子的颜色样式
                if (that.selectedMonth === params.name) {
                  // 当前的金额
                  that.incomeNum = params.value
                  return '#FFFFFF';
                } else {
                  return '#FF868F';
                }
              }
            },
            //鼠标悬浮时柱体颜色
            emphasis: {
              color: '#FFB6BB',
            }
          }
        }]
      }
      // 绘制图表
      this.myChart.setOption(option);

      //点击事件
      // this.myChart.on('click', function(params) {
      //     //点击的柱子的名称、值
      //     that.selectedMonth = params.name;
      //     // that.incomeNum = params.value;

      //     console.log(that.selectedMonth)

      // that.getDonateInfo(false);
      //     // console.log('点击bar', params)
      //     //柱形图重构
      //     that.myChart.setOption(option);
      // })

      this.myChart.getZr().on('click', function (params) {
        // 获取横坐标和纵坐标的数据
        let pointInPixel = [params.offsetX, params.offsetY]

        // 计算出事件触发时当前横坐标对应的月份
        let xIndex = 0;
        if (that.myChart.containPixel('grid', pointInPixel))
          xIndex = that.myChart.convertFromPixel({ seriesIndex: 0 }, [params.offsetX, params.offsetY])[0];

        // 重新设置月份，月份从0开始计算的，所以需要 月份+1
        that.selectedMonth = that.monthToString(xIndex + 1);
        that.getDonateInfo(false);
        //柱形图重构
        that.myChart.setOption(option);
      });


    },
    //修改年份，默认选中1月份
    changeYear (year) {
      this.selectedYear = year;
      this.selectedMonth = '01'
      this.getDonateInfo(true);
    },
    //获取年份列表
    getYearList () {
      let nowYear = new Date().getFullYear();
      this.selectedYear = nowYear; //默认选中当前年份
      for (let index = 0; index < 5; index++) {
        this.yearList.push(nowYear - index)
      }
    },


  },
}
</script>

<style scoped lang='scss'>
.tabber {
  text-align: right;
  margin-top: 2px;
  margin-bottom: 22px;

  span {
    width: 80px;
    height: 40px;
    background: #fbfbfb;
    border: 1px solid #ebedf0;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    color: #999999;
    margin-right: 10px;
    cursor: default;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background: rgba(238, 16, 34, 0.05);
      border: 1px solid $main-color;
      color: $main-color;
    }

    &:not(.active):hover {
      color: rgba(229, 1, 19, 0.6);
      background: rgba(229, 1, 19, 0.05);
      border: 1px solid rgba(229, 1, 19, 0.3);
    }
  }
}

.header {
  background: $main-color;
  border-radius: 8px 8px 0px 0px;
  color: #fff;
  padding: 20px;

  > .title {
    font-size: 22px;
  }

  .main-box {
    display: flex;
    margin-top: 10px;

    .chart-content {
      flex: 2;
    }

    .tatol-content {
      flex: 1;
      padding: 0 22px;
      text-align: center;

      .name {
        font-size: 30px;
        padding-bottom: 18px;
        border-bottom: 1px solid rgba(255, 78, 92, 0.43);
      }

      .income {
        font-size: 18px;
        margin-top: 44px;

        span {
          font-size: 26px;
        }
      }

      .p {
        font-size: 14px;
        color: #ff868f;
        margin-bottom: 96px;
      }
    }
  }
}

.list-content {
  .list {
    height: 432px;
    overflow-y: scroll;
    border-radius: 0 0 8px 8px;
  }

  .data-null {
    text-align: center;
    line-height: 80px;
  }

  .item {
    display: flex;
    background: #fbfbfb;

    &:nth-child(2n) {
      background: #f5f5f5;
    }

    &.title {
      background: #d90112;

      > div {
        color: #fff;
        font-size: 16px;
      }
    }

    > div {
      flex: 1;
      text-align: center;
      height: 48px;
      line-height: 48px;
      font-size: 14px;
      color: #666666;
    }
  }
}
</style>